import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './assets/logo_wide.png'
import Snackbar from '@mui/material/Snackbar';
import { Container, Row, Col, Form, Card, Button, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePasswordPage = ({ baseUrl, authId, authToken, isAuthenticated }) => {
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };
    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        navigate('/')
    };


    console.log("isAuthenticated: " + isAuthenticated);
    if (isAuthenticated !== true) {
        navigate('/login');
    }

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match!");
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/v1/crm/changepassword`, {
                new_password: newPassword,
            }, {
                headers: {
                    'X-AUTH-ID': authId,
                    'X-AUTH-TOKEN': authToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                openSnackbar('Password updated successfully', "warning");
            } else {
                // Handle any non-200 response here
                openSnackbar("Error updating password.", "error");
            }
        } catch (error) {
            // Handle error during the API call
            openSnackbar("Error updating password. Please try again.", "error");
        }
    };

    return (
        <div>
            <Container fluid="md" className="text-center">
                <Row className="justify-content-md-center align-items-center mb-4">
                    <Col>
                        <img src={logo} className="App-logo" alt="logo" />
                    </Col>
                </Row>
                <br />
                <Row className="justify-content-md-center align-items-center mb-4">
                    <Col xs={12} md={6}>
                        <Card className="login-card">
                            {/*<Card.Img variant="top" src = {logo} className="App-logo" />*/}
                            <Card.Body>
                                <Card.Title>Update Password</Card.Title>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter new password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm new password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                    </Form.Group>

                                    <Button variant="primary" onClick={handlePasswordChange}>
                                        Update Password
                                    </Button>
                                </Form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Snackbar open={snackbarOpen}
                          autoHideDuration={1000}
                          onClose={closeSnackbar}
                          anchorOrigin={{
                              vertical: 'bottom', horizontal: 'center'
                          }}>
                    <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </div>
    );
};

export default ChangePasswordPage;
