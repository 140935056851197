import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Table, Button, Container, Modal} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

const LeadsTable = ({baseUrl, authId, authToken, isAuthenticated}) => {
    const [data, setData] = useState([]);
    const [start, setStart] = useState(0);
    const [limit] = useState(5); // assuming you always want 3 records at a time
    const [next, setNext] = useState(null);
    const [selectedLead, setSelectedLead] = useState(null);

    const navigate = useNavigate();
    console.log("isAuthemitcated: " + isAuthenticated)
    if (isAuthenticated !== true) {
        navigate('/login')
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(baseUrl + '/v1/crm/lead', {
                    params: {
                        start,
                        limit,
                    },
                    headers: {
                        'X-AUTH-ID': authId,
                        'X-AUTH-TOKEN': authToken,
                    },
                });

                setData(prevData => [...prevData, ...response.data.data]);
                setNext(response.data.next);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [authId, authToken, start, limit]);

    return (
        <Container>
            <br/>
            <h2>All Leads</h2>
            <br/>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>CreatedBy</th>
                    <th>StudentName</th>
                    <th>Mobile</th>
                    <th>Clazz</th>
                    <th>LeadScore</th>
                    <th>OtpVerified</th>
                </tr>
                </thead>
                <tbody>
                {data.map(item => (
                    <tr key={item.id} onClick={() => setSelectedLead(item)}>
                        <td>{item.createdBy}</td>
                        <td>{item.studentName}</td>
                        <td>{item.mobile}</td>
                        <td>{item.clazz}</td>
                        <td>{item.leadScore}</td>
                        <td>{item.otpVerified ? "Yes" : "No"}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <br/>
            <center>
                {next !== -1 && (
                    <Button onClick={() => setStart(next)}>Load More</Button>
                )}
            </center>

            <br/>
            <br/>

            <Modal show={selectedLead !== null} onHide={() => setSelectedLead(null)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Lead Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedLead && (
                        <Table bordered>
                            <tbody>
                            {Object.entries(selectedLead).map(([key, value]) => (
                                <tr key={key}>
                                    <td><strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong></td>
                                    <td>
                                        {key === "location" ? (
                                            <div>
                                                {value} <br/>
                                                <a
                                                    href={`https://www.google.com/maps/search/?api=1&query=${value}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View on Google Maps
                                                </a>
                                            </div>

                                        ) : key === "createdAt" || key === "modifiedAt" ? (
                                            new Date(value).toLocaleString()
                                        ) : (
                                            value.toString()
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>

            </Modal>
        </Container>
    );

};

export default LeadsTable;
