import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {Container, Row, Col, Form, Button, Modal} from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './LeadForm.css';
import logo from './assets/logo.png';
import { useNavigate } from 'react-router-dom';


const LeadForm = ({ baseUrl, userId, token, isAuthenticated}) => {
    const navigate = useNavigate();
    console.log("isAuthemitcated: " + isAuthenticated)
    if(isAuthenticated !== true) {
        navigate('/login')
    }

    const [showModal, setShowModal] = useState(false);
    const [hideOtp, setHideOtp] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [locationPermission, setLocationPermission] = useState(false);

    const handleErrorModalClose = () => {
        setShowErrorModal(false);
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const askLocationPermission = useCallback(() => {
        // Your logic to ask for location permission
        if (!navigator.geolocation) {
            openSnackbar('Not supported in your browser', 'error');
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const {latitude, longitude} = position.coords;
                setFormData((prev) => ({
                    ...prev, location: `${latitude},${longitude}`,
                }));
                openSnackbar('Location permission granted', 'success');
                setShowModal(false);
            }, () => {
                openSnackbar('Location permission denied', 'error');
                setShowModal(true);
            });
        }
    }, []);

    useEffect(() => {
        askLocationPermission();
    }, [askLocationPermission]);

    const handleModalClose = () => {
        setShowModal(false);
        askLocationPermission();
    };


    const [requestId, setRequestId] = useState('');
    const [formData, setFormData] = useState({
        mobile: '',
        student_name: '',
        parent_name: '',
        "class": '',
        lead_score: '',
        otp: '',
        created_by: '',
        location: '',
        remarks: ''
    });

    const options = ['Class 8', 'Class 9', 'Class 10'];

    const resetForm = () => {

        askLocationPermission()
        setRequestId(`${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}${uuidv4().slice(0, 5)}`);

        setFormData({
            mobile: '',
            student_name: '',
            parent_name: '',
            "class": '',
            lead_score: '',
            otp: '',
            created_by: '',
            location: '',
            remarks: ''
        });

        setHideOtp(false)
    }


    useEffect(() => {
        setRequestId(`${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}${uuidv4().slice(0, 5)}`);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setFormData({
                    ...formData, location: `${position.coords.latitude},${position.coords.longitude}`,
                });
            }, () => {
                openSnackbar('Permissions required', 'warning');
            });
        }
    }, [setFormData]);

    const sendOTP = async () => {
        try {
            const response = await axios.post(baseUrl + '/v1/user/otp/send', {
                mobile: formData.mobile, request_id: requestId,
            }, {headers: {'X-CLIENT': 'lead-gen-android', 'Content-Type': 'application/json'}});
            if (response.status === 200) {
                const message = response.data.message || 'Survey Code Sent successfully';
                openSnackbar(message, "success");
                setHideOtp(true)
            } else {
                const message = response.data.message || "Something went wrong. Try again";
                openSnackbar(message, 'warning');
            }
        } catch (err) {
            openSnackbar("Something went wrong: " + err.message, 'error');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const leadScoreValue = parseInt(formData.lead_score);
            axios.post(baseUrl + '/v1/crm/lead', {
                ...formData, request_id: requestId, lead_score: leadScoreValue
            }, {
                headers: {
                    'Content-Type': 'application/json', 'X-AUTH-ID': userId, 'X-AUTH-TOKEN': token
                }
            }).then(response => {
                if(response.status === 401) {
                    setError("Session timed out. Login again");
                    navigate('/login');
                } else if (response.status === 200) {
                    const message = response.data.message || "Lead submitted successfully.";
                    resetForm()
                    setError(message);
                    setShowErrorModal(true);
                } else {
                    console.log(response)
                    const message = response.data.message || "Something went wrong, try again";
                    openSnackbar(message, "warning");
                }
            }).catch(error => {
                // Handle the error
                // Extract the error message, or use a default if it's null or undefined
                const errorMessage = (error.response && error.response.data.message) || 'An error occurred';
                console.log(errorMessage)
                openSnackbar(errorMessage, "error");
            });
        } catch (err) {
            openSnackbar("Something went wrong, try again", "error");
        }
        ;
    };

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    return (<Container>
        <Row className="justify-content-md-center">
            <Col>
                <Container fluid="md" className="text-center">
                    <Row className="justify-content-md-center align-items-center mb-4">
                        <Col>
                            <img src={logo} className="App-logo" alt="logo" />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center align-items-center">
                        <Col>
                            <h1>Qlass Enrollment Form</h1>
                            <br/>
                        </Col>
                    </Row>
                </Container>


                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Mobile Number</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type="tel"
                                // placeholder="Enter Mobile Number"
                                name="mobile"
                                value={formData.mobile || ''}
                                onChange={handleChange}
                                required
                                pattern="[0-9]{10}"
                                maxLength={10}
                                onClick={() => {
                                    if (hideOtp) {
                                        openSnackbar("Mobile number cannot be modified after OTP is sent", "info");
                                    }
                                }}
                                disabled={hideOtp}
                                autoComplete="off"
                            />
                            <div className="input-group-append">
                                <Button variant="outline-primary" onClick={sendOTP} disabled={hideOtp}>
                                    Get Survey Code
                                </Button>
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group controlId="student_name">
                        <Form.Label>Student Name</Form.Label>
                        <Form.Control type="text" name="student_name" value={formData.student_name}
                                      onChange={handleChange} placeholder="Student Name" maxLength={100} autoComplete="off" />
                    </Form.Group>
                    <Form.Group controlId="parent_name">
                        <Form.Label>Parent Name</Form.Label>
                        <Form.Control type="text" name="parent_name" value={formData.parent_name}
                                      onChange={handleChange} placeholder="Parent Name" maxLength={100} autoComplete="off" />
                    </Form.Group>
                    <Form.Group controlId="class">
                        <Form.Label>Current Class</Form.Label>
                        <Form.Control as="select" name="class" value={formData.class}
                                      onChange={handleChange}>
                            {options.map((option, index) => (<option key={index} value={option} autoComplete="off" >
                                {option}
                            </option>))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="lead_score">
                        <Form.Label>Lead Score Value</Form.Label>
                        <Form.Control type="number" name="lead_score" value={formData.lead_score}
                                      onChange={handleChange} placeholder="Lead Score Value" autoComplete="off" />
                    </Form.Group>
                    <Form.Group controlId="otp">
                        <Form.Label>4 Digit Survey Code</Form.Label>
                        <Form.Control type="text" name="otp" value={formData.otp} onChange={handleChange}
                                      placeholder="Unique Survey Code" maxLength={4} required autoComplete="off" />
                    </Form.Group>
                    <Form.Group controlId="remarks">
                        <Form.Label>Remarks</Form.Label>
                        <Form.Control type="text" name="remarks" value={formData.remarks}
                                      onChange={handleChange} placeholder="Remarks" autoComplete="off" />
                    </Form.Group>
                    <br/>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Col>
        </Row>

        <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Location Permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                We need your location permission to proceed. Please grant the permission.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleModalClose}>
                    Retry
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showErrorModal} onHide={handleErrorModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleErrorModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Snackbar open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={closeSnackbar}
                  anchorOrigin={{
                      vertical: 'bottom', horizontal: 'center'
                  }}>
            <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Container>);
};

export default LeadForm;
