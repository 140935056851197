// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.App-logo {*/
/*    width: 20%;  !* by default, take the full width of its container *!*/
/*    height: auto; !* keep the aspect ratio *!*/
/*}*/

.login-card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/*.login-card {*/
/*    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA,cAAc;AACd,0EAA0E;AAC1E,gDAAgD;AAChD,IAAI;;AAEJ;IACI,uCAAuC;AAC3C;;AAEA,gBAAgB;AAChB,iDAAiD;AACjD,IAAI","sourcesContent":["/*.App-logo {*/\n/*    width: 20%;  !* by default, take the full width of its container *!*/\n/*    height: auto; !* keep the aspect ratio *!*/\n/*}*/\n\n.login-card {\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n}\n\n/*.login-card {*/\n/*    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
