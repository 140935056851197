import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './assets/logo_wide.png'
import Snackbar from '@mui/material/Snackbar';
import { Container, Row, Col, Form, Card, Button, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateUser = ({ baseUrl, authId, authToken, isAuthenticated }) => {
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState(() => new Set());

    const addSelectedRole = (item) => {
        setSelectedRoles(prev => new Set(prev).add(item));
    }

    const removeSelectedRole = item => {
        setSelectedRoles(prev => {
            const next = new Set(prev);
            next.delete(item);
            return next;
        });
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };
    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        navigate('/')
    };


    console.log("isAuthenticated: " + isAuthenticated);
    if (isAuthenticated !== true) {
        navigate('/login');
    }

    const allRoles = ["admin", "teacher", "student", "agent", "owner", "basic"];

    const roles = [
        {id: "admin", name: "Admin"},
        {id: "teacher", name: "Teacher"},
        {id: "student", name: "Student"},
        {id: "agent", name: "Agent"},
        {id: "basic", name: "Basic"}
    ]

    const handlePasswordChange = async () => {
        try {
            const arrayList = Array.from(selectedRoles)
            const response = await axios.post(`${baseUrl}/v1/crm/user`, {
                username: newPassword,
                password: confirmPassword,
                roles: arrayList
            }, {
                headers: {
                    'X-AUTH-ID': authId,
                    'X-AUTH-TOKEN': authToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                openSnackbar('User created successfully', "warning");
            } else {
                // Handle any non-200 response here
                openSnackbar("Error creating user.", "error");
            }
        } catch (error) {
            // Handle error during the API call
            openSnackbar("Error creating user. Please try again.", "error");
        }
    };

    return (
        <div>
            <Container fluid="md" className="text-center">
                <Row className="justify-content-md-center align-items-center mb-4">
                    <Col>
                        <img src={logo} className="App-logo" alt="logo" />
                    </Col>
                </Row>
                <br />
                <Row className="justify-content-md-center align-items-center mb-4">
                    <Col xs={12} md={6}>
                        <Card className="login-card">
                            {/*<Card.Img variant="top" src = {logo} className="App-logo" />*/}
                            <Card.Body>
                                <Card.Title>Create User</Card.Title>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>User Name</Form.Label>
                                        <Form.Control type="email" placeholder="Enter new username" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm new password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                    </Form.Group>
                                    {/* Add checkboxes for selecting roles */}
                                    <Form.Group className="mb-3">
                                        {roles.map((role) => (
                                            <div key={role.id} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="group-roles"
                                                    style={{ marginRight: '10px' }}
                                                    id={role.id}
                                                    label={role.name}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            addSelectedRole(role.id);
                                                        } else {
                                                            removeSelectedRole(role.id);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>


                                    <Button variant="primary" onClick={handlePasswordChange}>
                                        Create user
                                    </Button>
                                </Form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Snackbar open={snackbarOpen}
                          autoHideDuration={1000}
                          onClose={closeSnackbar}
                          anchorOrigin={{
                              vertical: 'bottom', horizontal: 'center'
                          }}>
                    <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </div>
    );
};

export default CreateUser;
