import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {Container, Row, Col, Form, Button, Modal, Dropdown, Card} from 'react-bootstrap';

const TopicSelectionPage = ({baseUrl, userId, token, isAuthenticated}) => {

    const navigate = useNavigate();
    if (isAuthenticated !== true) {
        navigate('/login')
    }


    const [data, setData] = useState({});
    const [selected, setSelected] = useState({
        classId: '', subjectId: '', chapterId: '', topicId: '', conceptId: '',
    });


    const getTopicContent = () => {
        axios.get(baseUrl + '/v1/crm/content/student', {
            headers: {
                'X-AUTH-ID': userId, 'X-AUTH-TOKEN': token,
            },
        }).then((response) => {
            setData(response.data);
        }).catch((error) => {
            console.log('Error fetching data:', error);
        });
    }


    useEffect(() => {
        getTopicContent()
    }, []);

    const handleSelect = (key, value) => {
        if (key === "classId") {
            setSelected({
                ...selected, [key]: value, subjectId: '', chapterId: '', topicId: '',  conceptId: '',
            });
        } else if (key === "subjectId") {
            setSelected({
                ...selected, [key]: value, chapterId: '', topicId: '', conceptId: '',
            });
        } else if (key === "chapterId") {
            setSelected({
                ...selected, [key]: value, topicId: '',  conceptId: '',
            });
        } else if (key === "topicId") {
            setSelected({
                ...selected, [key]: value, conceptId: '',
            });
        } else {
            setSelected({
                ...selected, [key]: value,
            });
        }
    };

    const handleNext = () => {
        navigate('/question/create', {state: {baseUrl, userId, token, isAuthenticated, selected, topicData: data}});
    };

    return (<div>
        <Container>
            <Row className="justify-content-md-center">
                <Row className="justify-content-md-center align-items-center">
                    <Col>
                        <h1>Submit a Question</h1>
                        <br/>
                    </Col>
                </Row>
            </Row>
            <br/>
            <br/>
            <Row className="justify-content-md-center">
                <Card style={{width: "36rem"}} className="bg-light text-black">
                    <Col>
                        <br/>
                        <h3>Select Topic</h3>
                        <br/>
                        <Form>
                            <Form.Label>Select Class</Form.Label>
                            <Form.Select onChange={(e) => handleSelect('classId', e.target.value)} required>
                                <option>Select</option>
                                {data.userClasses?.map((userClass) => (
                                    <option key={userClass.id} value={userClass.id}>{userClass.name}</option>))}
                            </Form.Select>

                            <Form.Group controlId="subjectSelect">
                                <Form.Label>Select Subject</Form.Label>
                                <Form.Select onChange={(e) => handleSelect('subjectId', e.target.value)} required>
                                    <option>Select</option>
                                    {data.subjects?.filter(subject => (subject.classId).toString() === (selected.classId).toString()).map((subject) => (
                                        <option key={subject.id} value={subject.id}>{subject.name}</option>))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group controlId="chapterSelect">
                                <Form.Label>Select Chapter</Form.Label>
                                <Form.Select onChange={(e) => handleSelect('chapterId', e.target.value)} required>
                                    <option>Select</option>
                                    {data.chapters?.filter(chapter => (chapter.subjectId).toString() === (selected.subjectId).toString()).map((chapter) => (
                                        <option key={chapter.id} value={chapter.id}>{chapter.name}</option>))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group controlId="topicSelect">
                                <Form.Label>Select Topic</Form.Label>
                                <Form.Select onChange={(e) => handleSelect('topicId', e.target.value)} required>
                                    <option>Select</option>
                                    {data.topics?.filter(topic => (topic.chapterId).toString() === (selected.chapterId).toString()).map((topic) => (
                                        <option key={topic.id} value={topic.id}>{topic.name}</option>))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group controlId="conceptSelect">
                                <Form.Label>Select Concept</Form.Label>
                                <Form.Select onChange={(e) => handleSelect('conceptId', e.target.value)} required>
                                    <option>Select</option>
                                    {data.concepts?.filter(concept => (concept.topicId).toString() === (selected.topicId).toString()).map((concept) => (
                                        <option key={concept.id} value={concept.id}>{concept.name}</option>))}
                                </Form.Select>
                            </Form.Group>
                            <br/>
                            <Button onClick={handleNext}>Next</Button>
                        </Form>
                        <br/>
                    </Col>
                </Card>
            </Row>
        </Container>
    </div>);
};

export {TopicSelectionPage};
