import React from 'react';
import {Button, Container, Row, Col} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'; // Helps with integrating React Router with Bootstrap
import logo from './assets/logo_wide.png'; // Replace with your logo's path

import {useNavigate} from 'react-router-dom';


const HomePage = ({baseUrl, userId, token, isAuthenticated, roles}) => {
    const navigate = useNavigate();

    if (isAuthenticated !== true) {
        navigate('/login');
    }

    const allRoles = ["admin", "teacher", "student", "agent", "owner", "basic"];

    const isAdmin = roles?.includes("admin") || false;
    const isTeacher = roles?.includes("teacher")|| false;
    const isAgent = roles?.includes("agent")|| false;
    const isOwner = roles?.includes("owner")|| false;

    return (<Container className="mt-5">
        {/* Header */}
        <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6} className="text-center">
                <img src={logo} alt="Company Logo" className="img-fluid mb-3"/>
            </Col>
        </Row>
        <br/>
        {/* Buttons */}
        <Col>
            {isAgent && (<div>
                <Row xs={12} md={4} className="justify-content-center">
                    <LinkContainer to="/leads">
                        <Button variant="primary" size="lg">
                            Create Lead
                        </Button>
                    </LinkContainer>
                </Row>
                <br/>
                <Row xs={12} md={4} className="justify-content-center">
                    <LinkContainer to="/viewLeads">
                        <Button variant="primary" size="lg">
                            View Leads
                        </Button>
                    </LinkContainer>
                </Row>
                <br/>
            </div>)}
            {isAdmin && (<div>
                <Row xs={12} md={4} className="justify-content-center">
                    <LinkContainer to="/createUser">
                        <Button
                            variant="primary"
                            size="lg">
                            Create User
                        </Button>
                    </LinkContainer>
                </Row>
                <br/>
            </div>)}
            {isTeacher && (<div>
                <Row xs={12} md={4} className="justify-content-center">
                    <LinkContainer to="/question">
                        <Button
                            variant="primary"
                            size="lg">
                            Create Questions
                        </Button>
                    </LinkContainer>
                </Row>
                <br/>
            </div>)}
            <Row xs={12} md={6} className="justify-content-center">
                <LinkContainer to="/changePassword">
                    <Button variant="info" size="md">
                        Change Password
                    </Button>
                </LinkContainer>
            </Row>
            <br/>

            <br/>
            <Row xs={12} md={6} className="justify-content-center">
                <LinkContainer to="/login">
                    <Button variant="outline-danger" size="md">
                        Logout
                    </Button>
                </LinkContainer>
            </Row>
        </Col>
    </Container>);
}

export default HomePage;
