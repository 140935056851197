import React, {useState, useEffect} from 'react';
import {BrowserRouter, Routes, Route, useLocation, Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import LeadForm from './LeadForm';
import LeadsTable from './LeadsTable';
import HomePage from './HomePage';
import Login from './Login'
import ChangePasswordPage from './ChangePasswordPage'
import CreateUser from './CreateUser'
import {QuestionForm} from './question-form/QuestionForm'
import {TopicSelectionPage} from './question-form/TopicSelectionPage'
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {Button} from 'react-bootstrap';

const NavigationBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
    };

    const isHomePage = location.pathname === '/';
    const isLoginPage = location.pathname === '/login';

    return (<nav className="navbar">
            {!isHomePage && !isLoginPage && (<Button onClick={handleBack}> Back </Button>)}
            {/*<Link to="/" className="navbar-item">Home</Link>*/}
            {/*<Link to="/leads" className="navbar-item">Lead Form</Link>*/}
            {/*<Link to="/viewLeads" className="navbar-item">View Leads</Link>*/}
            {/*<Link to="/changePassword" className="navbar-item">Change Password</Link>*/}
            {/*<Link to="/createUser" className="navbar-item">Create User</Link>*/}
        </nav>);
};

const RouteWrapper = ({baseUrl, userId, setUserId, token, setToken, isAuthenticated, setIsAuthenticated,
                      roles, setRoles}) => {
    const location = useLocation();



    return (<SwitchTransition>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Routes location={location}>
                <Route path="/" element={<HomePage baseUrl={baseUrl} userId={userId} token={token}
                                                   isAuthenticated={isAuthenticated} roles={roles} />}/>
                <Route path="/leads" element={<LeadForm baseUrl={baseUrl} userId={userId} token={token}
                                                        isAuthenticated={isAuthenticated}/>}/>
                <Route path="/login"
                       element={<Login baseUrl={baseUrl} setToken={setToken} setUserId={setUserId}
                                       setIsAuthenticated={setIsAuthenticated} setRoles={setRoles}/>}/>
                <Route path="/viewLeads"
                       element={<LeadsTable baseUrl={baseUrl} authId={userId} authToken={token}
                                            isAuthenticated={isAuthenticated}/>}/>
                <Route path="/changePassword"
                       element={<ChangePasswordPage baseUrl={baseUrl} authId={userId} authToken={token}
                                                    isAuthenticated={isAuthenticated}/>}/>
                <Route path="/createUser"
                       element={<CreateUser baseUrl={baseUrl} authId={userId} authToken={token}
                                            isAuthenticated={isAuthenticated}/>}/>
                <Route path="/question"
                       element={<TopicSelectionPage baseUrl={baseUrl} userId={userId} token={token}
                                                    isAuthenticated={isAuthenticated}/>}/>
                <Route path="/question/create"
                       element={<QuestionForm baseUrl={baseUrl} userId={userId} token={token}
                                                    isAuthenticated={isAuthenticated} selected=""/>}/>
            </Routes>
        </CSSTransition>
    </SwitchTransition>);
};


const App = () => {
    const storedUserId = localStorage.getItem('userId') || "";
    const storedToken = localStorage.getItem('token') || "";
    const storedRoles = localStorage.getItem('roles') || ["basic"];

    const [userId, setUserId] = useState(storedUserId);
    const [token, setToken] = useState(storedToken);
    const [roles, setRoles] = useState(storedRoles);
    const [isAuthenticated, setIsAuthenticated] = useState(!!storedUserId && !!storedToken);
    const [isLoading, setIsLoading] = useState(false);
    const [minLoaderTimeElapsed, setMinLoaderTimeElapsed] = useState(false);


    useEffect(() => {
        const minLoaderDisplayTime = 500;  // Minimum display time in milliseconds
        const timer = setTimeout(() => {
            setMinLoaderTimeElapsed(true);
        }, minLoaderDisplayTime);

        return () => clearTimeout(timer);  // Clean up the timer
    }, []);

    const startLoading = () => {
        setIsLoading(true);
        setMinLoaderTimeElapsed(false);
    }

    const endLoading = () => {
        setIsLoading(false)
    }

    const baseUrl = "http://api.qlass.in"
    // const baseUrl = "http://localhost:9005"
    useEffect(() => {
        const checkAuthentication = async () => {
            startLoading()
            try {
                const userId = localStorage.getItem('userId')
                const token = localStorage.getItem('token')
                const roles = localStorage.getItem('roles')
                setUserId(userId)
                setToken(token)
                setRoles(roles)
                const response = await axios.get(baseUrl + '/v1/crm/areuok', {
                    headers: {
                        'X-AUTH-ID': userId, 'X-AUTH-TOKEN': token
                    }
                });

                if (response.status === 401) {
                    console.error('unauthorized')
                } else if (response.status === 200) {
                    console.log("Sucess")
                    setIsAuthenticated(true);
                } else {
                    console.error("failed")
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                localStorage.clear()
                setIsAuthenticated(false);
            }
            endLoading()
        };
        checkAuthentication();
    }, []);

    return (<BrowserRouter>
            <div className="route-wrapper">
                <NavigationBar/>
                {isLoading || !minLoaderTimeElapsed ? (<div className="loader-wrapper">
                    <div className="loader"></div>
                </div>) : (<RouteWrapper
                    baseUrl={baseUrl}
                    userId={userId}
                    setUserId={setUserId}
                    token={token}
                    setToken={setToken}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    roles={roles}
                    setRoles={setRoles}
                />)}
            </div>
        < /BrowserRouter>

    );
};

export default App;
