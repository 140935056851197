import React, {useState, useEffect} from "react";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {Container, Row, Col, Form, Button, Modal, Dropdown, Card} from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const QuestionForm = ({baseUrlQ, userIdQ, tokenQ, isAuthenticatedQ, selectedQ}) => {
    const location = useLocation();
    const { baseUrl, userId, token, isAuthenticated, selected } = location.state || { baseUrl: baseUrlQ, userId: userIdQ, token: tokenQ, isAuthenticated: isAuthenticatedQ, selected: selectedQ };
    const [topicData, setTopicData] = useState({});

    useEffect(() => {
        setRequestId(`${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}${uuidv4().slice(0, 5)}`);

        getTopicContent()
    }, []);

    const navigate = useNavigate();
    if(isAuthenticated !== true) {
        navigate('/login')
    }

    const getTopicContent = () => {
        if(selected?.topicId === undefined) {
            return;
        } else {
            axios.get(baseUrl + '/v1/crm/content/student/topic/' + selected?.topicId, {
                params: {
                    conceptId: selected?.conceptId, // Add the conceptId as a query parameter
                },
                headers: {
                    'X-AUTH-ID': userId, 'X-AUTH-TOKEN': token,
                },
            }).then((response) => {
                setTopicData(response.data);
            }).catch((error) => {
                console.log('Error fetching data:', error);
            });
        }
    }

    const [question, setQuestion] = useState(null);
    const [solution, setSolution] = useState(null);
    const [questionType, setQuestionType] = useState(null);
    const [choices, setChoices] = useState([]);
    const [correctChoices, setCorrectChoices] = useState(() => new Set());
    const [difficulty, setDifficulty] = useState(null);
    const [assertion, setAssertion] = useState(null);
    const [minutesToSolve, setMinutesToSolve] = useState(null);
    const [questionTag, setQuestionTag] = useState(null);
    const [marks, setMarks] = useState(null);

    // Error models COMMON - Starts here
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [showModal, setShowModal] = useState(false);

    const handleErrorModalClose = () => {
        setShowErrorModal(false);
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };
    // Error models COMMON - Ends here

    const addItem = (item, selectType) => {
        if(selectType === "radio") {
            setCorrectChoices(prev => new Set().add(item));
        } else {
            setCorrectChoices(prev => new Set(prev).add(item));
        }
    }

    const removeItem = item => {
        setCorrectChoices(prev => {
            const next = new Set(prev);
            next.delete(item);
            return next;
        });
    }

    const setChoice = (index, value) => {
        const newChoices = [...choices];
        newChoices[index] = value;
        setChoices(newChoices);
    }

    const [requestId, setRequestId] = useState('');
    const resetForm = () => {
        console.log("resetForm")
        setQuestion("");
        setSolution("");
        setQuestionType("");
        setChoices([]);
        setCorrectChoices(new Set());
        setDifficulty("");
        setAssertion("");
        setMarks("");
        setMinutesToSolve("");
        setQuestionTag("");
        setRequestId(`${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}${uuidv4().slice(0, 5)}`);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (questionType === "MCQ" || questionType === "MAQ") {
            if (correctChoices.size === 0) {
                openSnackbar("Please select the correct choice", "warning");
                return;
            }
        }
        const arrayList = Array.from(correctChoices).map(Number);
        console.log("correctChoices: " + correctChoices.size)
        axios.post(baseUrl + "/v1/crm/content/question", {
            topicId: Number(selected?.topicId),
            questionType,
            question,
            solution,
            choices,
            correctChoices: arrayList,
            difficulty,
            assertion,
            marks,
            minsToSolve: minutesToSolve,
            sourceExam: questionTag,
            subtopicId: Number(selected?.conceptId),
            requestId: requestId,
        }, {
            headers: {
                'Content-Type': 'application/json', 'X-AUTH-ID': userId, 'X-AUTH-TOKEN': token
            }
        }).then((response) => {
            if (response.status === 401) {
                setError("Session timed out. Login again");
                navigate('/login');
            } else if (response.status === 200) {
                const message = response.data.message || "Question submitted successfully.";
                resetForm()
                setError(message);
                setShowErrorModal(true);
            } else {
                console.log(response)
                const message = response.data.message || "Something went wrong, try again";
                openSnackbar(message, "warning");
            }
        }).catch((error) => {
            const errorMessage = (error.response && error.response.data.message) || 'An error occurred';
            console.log(errorMessage)
            openSnackbar(errorMessage, "error");
        });
    };
    //list of types of questions with key and display name
    const questionTypes = [{key: "MCQ", name: "Multiple Choice Question"}, {
        key: "MAQ",
        name: "Multiple Answer Question"
    }, {key: "Short", name: "Short Answer Question"}, {key: "Long", name: "Long Answer Question"}, {
        key: "Assertion",
        name: "Assertion Reason Question"
    },]

    return (<div>
        <Card style={{marginBottom: '2rem', marginLeft : '2rem', marginRight : '2rem'}} >
            <Card.Body>
                <Card.Title>Selected Topic</Card.Title>
                <Card.Text>
                    <strong>Class:</strong> {topicData?.userClass?.name || selected?.classId || "Not selected"}<br/>
                    <strong>Subject:</strong> {topicData?.subject?.name || selected?.subjectId || "Not selected"}<br/>
                    <strong>Chapter:</strong> {topicData?.chapter?.name || selected?.chapterId || "Not selected"}<br/>
                    <strong>Topic:</strong> {topicData?.topic?.name || selected?.topicId || "Not selected"}<br/>
                    <strong>Concept:</strong> {topicData?.concept?.name || selected?.conceptId || "Not selected"}
                </Card.Text>
            </Card.Body>
        </Card>
            <Row className="justify-content-md-center">
                <Card style={{width: "48rem", maxWidth: "90%", marginBottom: '10rem', marginLeft : '2rem', marginRight : '2rem'}}
                      className="bg-light text-black" border="primary" >
                    <Col>
                        <Form onSubmit={handleSubmit}>
                            <br/>
                            <Form.Label><h4>Question Type</h4></Form.Label>
                            <Form.Select
                                aria-label="Select a type"
                                onChange={(e) => setQuestionType(e.target.value)}
                                value={questionType}
                                required
                            >
                                <option>Select</option>
                                {questionTypes.map((type) => (
                                    <option key={type.key} value={type.key}>{type.name}</option>))}
                            </Form.Select>
                            <br/>
                            <Form.Label><h4>Question</h4></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Question"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                required
                            />
                            {questionType === "MCQ" ? (<div><br/>
                                <Form.Label><h4>Choices</h4><h6>(Select the correct option)</h6></Form.Label>
                                <Form.Group className="mb-3" controlId="formBasicRadio">
                                    {['1', '2', '3', '4'].map((index) => <Choice
                                                                                 choice={choices[index-1] || ""}
                                                                                 index={index}
                                                                                 setChoice={setChoice}
                                                                                 selectType={'radio'}
                                                                                 addItem={addItem}
                                                                                 removeItem={removeItem}
                                    />)}
                                </Form.Group>
                            </div>) : null}

                            {questionType === "MAQ" ? (<div><br/>
                                <Form.Label><h4>Choices</h4><h6>(Check all the correct options)</h6></Form.Label>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    {['1', '2', '3', '4'].map((index) => <Choice
                                                                                 choice={choices[index-1] || ""}
                                                                                 index={index}
                                                                                 // setChoice={(e) => setChoices(e.target.value)}
                                                                                 setChoice={setChoice}
                                                                                 selectType={'checkbox'}
                                                                                 addItem={addItem}
                                                                                 removeItem={removeItem}
                                    />)}
                                </Form.Group></div>) : null}

                            {questionType === "Assertion" ? (<div><br/>
                                <Form.Label><h4>Select the correct option</h4></Form.Label>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    {[{id: 1, name: 'A is true, R is true, R is the correct explanation of A'}, {
                                        id: 2,
                                        name: 'A is true, R is true, R is not the correct explanation of A'
                                    }, {id: 3, name: 'A is true, R is false'}, {
                                        id: 4,
                                        name: 'A is false, R is true'
                                    }, {id: 5, name: 'A is false, R is false'}].map((item) => (<Form.Check
                                        type="radio"
                                        label={item.name}
                                        name="assertion-group"
                                        id={item.id}
                                        onChange={(e) => setAssertion(item.id)}
                                    />))}
                                </Form.Group>
                            </div>) : null}
                            <br/>
                            <Form.Label><h4>Solution</h4></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Solution"
                                value={solution}
                                onChange={(e) => setSolution(e.target.value)}
                                required = {questionType !== "MCQ" && questionType !== "MAQ"}
                            />
                            <br/>
                            <Form.Label><h4>Difficulty</h4></Form.Label>
                            <div key="inline-radio" className="mb-3">
                                {[{id: 1, name: 'Remembering'}, {id: 2, name: 'Understanding'}, {
                                    id: 3, name: 'Applying'
                                }, {
                                    id: 4, name: 'Analyzing'
                                }, {id: 5, name: 'Evaluating'}, {id: 6, name: 'Creating'}].map((item) => (<Form.Check
                                    type="radio"
                                    label={item.name}
                                    name="difficulty-group"
                                    id={item.id}
                                    onChange={(e) => setDifficulty(item.id)}
                                />))}
                            </div>

                            <Row>
                                <Col xs = {6} sm = {3} md = {3} >
                                    <Form.Label><h6>Time to solve</h6></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Minutes"
                                        value={minutesToSolve}
                                        onChange={(e) => setMinutesToSolve(e.target.value)}
                                    />
                                </Col>
                                <Col  xs = {6} sm = {3} md = {3}>
                                    <Form.Label><h6>Marks</h6></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Marks"
                                        value={marks}
                                        onChange={(e) => setMarks(e.target.value)}
                                    />
                                </Col>
                                <Col xs = {12} sm = {6} md = {6}>
                                    <Form.Label><h6>Question Exam Tag</h6></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Question Tags"
                                        value={questionTag}
                                        onChange={(e) => setQuestionTag(e.target.value)}
                                        maxLength={255}
                                    />
                                </Col>
                            </Row>

                            <br/>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            <Button variant="secondary" onClick={resetForm} style = {{marginLeft: 20}}>
                                Reset
                            </Button>
                            <br/><br/>
                            <div></div>
                        </Form>
                    </Col>
                </Card>
            </Row>
            <br/><br/>


        <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Location Permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                We need your location permission to proceed. Please grant the permission.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleModalClose}>
                    Retry
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showErrorModal} onHide={handleErrorModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleErrorModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Snackbar open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={closeSnackbar}
                  anchorOrigin={{
                      vertical: 'bottom', horizontal: 'center'
                  }}>
            <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
        </div>);
};

export {QuestionForm};


//Separate component for choices to be added
const Choice = ({choice, index, setChoice, selectType, addItem, removeItem}) => {
    return (<Form.Group className="mb-3" controlId="formBasicCheckbox">
            <div className="input-group">
                <div className="input-group-append">
                    <Form.Check
                        type={selectType}
                        label={index}
                        name="group"
                        style={{
                            alignSelf: 'center', marginRight: '10px', marginLeft: '20px'
                        }} // Adjust margin as needed
                        onChange={(e) => {
                            if (e.target.checked) {
                                console.log("addItem: " + index)
                                addItem(index, selectType);
                            } else {
                                console.log("removeItem: " + index)
                                removeItem(index);
                            }
                        }}
                    />
                </div>
                <Form.Control
                    type="textarea"
                    value={choice}
                    rows={3}
                    onChange={(e) => setChoice(index - 1, e.target.value)}
                    style={{flex: 1}} // Allow the input to take the remaining width
                    required
                />
            </div>
        </Form.Group>);
}