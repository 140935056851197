import React, {useState} from 'react';
import axios from 'axios';
import {Container, Row, Col, Form, Button, Card, FloatingLabel} from 'react-bootstrap';
import logo from './assets/logo_wide.png'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './Login.css';
import {useNavigate} from 'react-router-dom';


const Login = ({baseUrl, setUserId, setToken, setIsAuthenticated, setRoles}) => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };
    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = baseUrl + '/v1/crm/login'
        try {
            const response = await axios.post(url, {
                username: username, password: password
            }, {
                headers: {'Content-Type': 'application/json'}
            });

            if (response.status === 200) {
                const userId = response.data.userId || ""
                const token = response.data.token || ""
                const roles = response.data.roles || ["basic"]
                if (userId && token && userId !== "" && token !== "") {
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('token', token);
                    localStorage.setItem('roles', roles);
                    setUserId(userId)
                    setToken(token)
                    setRoles(roles)
                    setIsAuthenticated(true)
                    navigate('/');
                }
            } else {
                console.log(response)
                const message = response.data.message || "Something went wrong, try again";
                openSnackbar(message, "warning");
                setIsAuthenticated(false)
            }
        } catch (error) {
            const errorMessage = (error.response && error.response.data.message) || 'An error occurred';
            console.log(error)
            openSnackbar(errorMessage, "error");
        }
    };

    return (<Container fluid="md" className="text-center">
            {/*<Row className="justify-content-md-center align-items-center mb-4">*/}
            {/*    <Col>*/}
            {/*        <img src={logo} className="App-logo" alt="logo" />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <br/>
            <Row className="justify-content-md-center align-items-center mb-4">
                <Col xs={12} md={6}>
                    <Card className="login-card">
                        <Card.Img variant="top" src={logo} className="App-logo"/>
                        <Card.Body>
                            <Card.Title>Welcome to Qlass CRM</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    {/*<Form.Label>Username</Form.Label>*/}
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Username"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    {/*<Form.Label>Password</Form.Label>*/}
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Password"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Login
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Snackbar open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={closeSnackbar}
                      anchorOrigin={{
                          vertical: 'bottom', horizontal: 'center'
                      }}>
                <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>);
};

export default Login;
